<template>
  <div>
    <div></div>
    <div>
      <div class="font-24 mt-10 ml-4">Edit Curated</div>
      <div class="justify-center" align="center">
        <!-- Top Bar Chart -->
        <v-card class="mt-2 mx-4">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form
                        name="details"
                        class="formPart text-left"
                        @submit.prevent="submitForm(id)"
                      >
                        <!-- Name -->
                        <div>
                          <div class="form-group col">
                            <label for="name">Title:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="title"
                              placeholder="Enter Name"
                              v-model.trim="details.title"
                              @input="setTouch('title')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.title.required && $v.details.title.$error"
                            >
                              title is required
                            </div>
                          </div>
                        </div>

                        <!-- <div>
                          <div class="form-group col">
                            <label for="Image"> Select Image:</label> <br />

                            <v-file-input
                              prepend-icon=""
                              type="file"
                              class="form-control"
                              id="designer-image"
                              v-model.trim="details.image"
                              @change="handleImage"
                              accept="image/jpeg, image/png"
                            ></v-file-input>
                          </div>
                          <div class="preview-image border p-2 mt-3 ml-3" v-if="url">
                            <v-img :src="url" height="230" contain></v-img>
                          </div>
                        </div> -->
                        <div class="d-flex">
                          <div class="form-group col-6">
                            <div>
                              <label for="image">Mobile Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (163 W &#10005; 221 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.imageMobile"
                                placeholder=" Select Image"
                                accept="image/jpeg, image/png"
                                @change="handleMobileImage"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="
                                  !$v.details.imageMobile.required && $v.details.imageMobile.$error
                                "
                              >
                                Mobile Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                              <v-img :src="mobileUrl" height="230" width="100%" contain></v-img>
                            </div>
                          </div>

                          <div class="form-group col-6">
                            <div>
                              <label for="image"> Desktop Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (305 W &#10005; 414 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.image"
                                placeholder=" Select Image"
                                @change="handleImage"
                                accept="image/jpeg, image/png"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="!$v.details.image.required && $v.details.image.$error"
                              >
                                Desktop Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                              <v-img :src="desktopUrl" height="230" contain></v-img>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex">
                          <div class="form-group col-6">
                            <div>
                              <label for="image">Mobile Banner Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (163 W &#10005; 221 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.mobileBannerImage"
                                placeholder=" Select Image"
                                accept="image/jpeg, image/png"
                                @change="handleMobileBannerImage"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="
                                  !$v.details.mobileBannerImage.required &&
                                  $v.details.mobileBannerImage.$error
                                "
                              >
                                Mobile Banner Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileBannerUrl">
                              <v-img
                                :src="mobileBannerUrl"
                                height="230"
                                width="300px"
                                contain
                              ></v-img>
                            </div>
                          </div>

                          <div class="form-group col-6">
                            <div>
                              <label for="image"> Desktop Banner Image: </label>
                              <div class="font-12 black--text font-weight-bold">
                                (305 W &#10005; 414 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.bannerImage"
                                placeholder=" Select Image"
                                @change="handleDesktopBannerImage"
                                accept="image/jpeg, image/png"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="
                                  !$v.details.bannerImage.required && $v.details.bannerImage.$error
                                "
                              >
                                Desktop Banner Image is required
                              </div>
                            </div>

                            <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopBanner">
                              <v-img
                                :src="desktopBanner"
                                height="230"
                                width="300px"
                                contain
                              ></v-img>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="link"
                              >Palettier SKU Codes:
                              <v-btn @click="addSku">Add</v-btn>
                            </label>

                            <div class="poppins-medium font-16 pl-4 d-block">
                              <v-row no-gutters>
                                <v-col
                                  cols="5"
                                  v-for="(item, index) in details.query"
                                  :key="index"
                                  class="px-6"
                                >
                                  <div class="d-flex">
                                    <v-text-field
                                      v-if="item"
                                      v-model="item.sku"
                                      width="150px"
                                      outlined
                                      dense
                                      class="mt-6 mr-4 px-2"
                                      >{{ item.sku }}</v-text-field
                                    >
                                    <img
                                      @click="deleteSKU(index)"
                                      src="@/assets/Images/remove.svg"
                                      alt="logo"
                                      class="mt-2"
                                    />
                                  </div>
                                </v-col>

                                <!-- <v-col cols="4">
                              <img
                                @click="confirm(image, 'image')"
                                src="@/assets/Images/remove.svg"
                                alt="logo"
                                class="pl-15"
                              />
                            </v-col> -->
                              </v-row>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="Link">Link:</label>
                            <input
                              type="text"
                              id="description"
                              class="form-control"
                              name="description"
                              placeholder="description"
                              v-model.trim="details.link"
                              @input="setTouch('description')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.link.required && $v.details.link.$error"
                            >
                              Link is required
                            </div>
                          </div>
                        </div>
                        <!-- button -->
                        <div class="btnPart text-center my-4">
                          <button type="submit" class="btn btn-success">Save</button>
                          <p class="typo__p" v-if="submitStatus === 'OK'">
                            Thanks for your submission!
                          </p>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'">
                            Please fill the form correctly.
                          </p>
                          <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import homeService from '../../services/homeService';
import imageUploadService from '../../services/imageUploadService';

export default {
  components: {},
  data() {
    return {
      details: {
        title: '',
        image: '',
        imageMobile: '',
        link: '',
        mobileBannerImage: '',
        bannerImage: '',
        query: [],
      },
      submitStatus: false,
      id: '',
      mobileUrl: null,
      desktopUrl: null,
      mobileBannerUrl: null,
      desktopBannerUrl: '',
      desktopBanner: '',
    };
  },
  validations: {
    details: {
      title: {
        required,
      },
      image: {
        required,
      },
      imageMobile: {
        required,
      },
      link: {
        required,
      },
      mobileBannerImage: {
        required,
      },
      bannerImage: {
        required,
      },
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getOneCuratedEdit(this.id);
    }
  },
  methods: {
    setTouch(fields) {
      if (fields === 'title') {
        this.$v.details.title.$touch();
      } else if (fields === 'image') {
        this.$v.details.image.$touch();
      } else if (fields === 'imageMobile') {
        this.$v.details.image.$touch();
      } else if (fields === 'link') {
        this.$v.details.link.$touch();
      } else if (fields === 'mobileBannerImage') {
        this.$v.details.mobileBannerImage.$touch();
      } else if (fields === 'bannerImage') {
        this.$v.details.bannerImage.$touch();
      }
    },
    // this is use for save button
    async submitForm(id) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });

        this.submitStatus = 'false';
      } else {
        console.log(id, 'id');
        this.submitStatus = 'true';
        let query = [];
        await this.details.query.map((sku) => {
          let data = {};
          console.log(sku, 'sku-here');
          if (sku.sku && sku.sku != '') {
            data.sku = sku.sku.trim();
            query.push(data);
          }
        });
        this.details.query = query;
        const result = await homeService.updateCuratedEditData(id, this.details);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewCuratedEdits');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
        console.log(result);
      }
    },
    async getOneCuratedEdit(id) {
      console.log(id, 'id');
      const result = await homeService.getOneCuratedEdit(id);
      this.details.title = await result.data.title;
      this.details.image = await result.data.image;
      this.details.imageMobile = await result.data.imageMobile;
      this.details.mobileBannerImage = await result.data.mobileBannerImage;
      this.details.bannerImage = await result.data.bannerImage;
      this.details.link = await result.data.link;
      this.desktopUrl = await result.data.image;
      this.mobileUrl = await result.data.imageMobile;
      this.desktopBannerurl = result.data.bannerImage;
      this.mobileBannerUrl = result.data.mobileBannerImage;
      this.desktopBanner = result.data.bannerImage;
      this.details.query = result.data.query;

      console.log(result, 'result');
      console.log(result.data.bannerImage, 'banner Image');
    },

    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('/viewCuratedEdits');
      });
    },
    async addSku() {
      const data = {};
      data.sku = '';
      this.details.query.push(data);
    },
    async handleImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.image = result.data.meta.location;
          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl = URL.createObjectURL(this.details.imageMobile);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.imageMobile = result.data.meta.location;

          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleDesktopBannerImage(file) {
      this.desktopBannerUrl = URL.createObjectURL(this.details.bannerImage);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.bannerImage = result.data.meta.location;

          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileBannerImage(file) {
      this.mobileBannerUrl = URL.createObjectURL(this.details.mobileBannerImage);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.mobileBannerImage = result.data.meta.location;

          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    deleteSKU(index) {
      this.details.query.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 40%;
  height: 250px;
}
</style>
